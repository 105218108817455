import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize
} from "@mui/material";
import { API_CALL } from "src/services/APICalls";
import CustomLoader from "src/components/custom-scroll/CustomLoader";
import { toast } from "react-toastify";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "src/components/shared/DashboardCard";
import { MyTheme } from "src/layouts/customTheme";
import { useNavigate, useParams } from "react-router";
import { ImnageValidation } from "src/components/commonfunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4
};

let initialState = {
  title: "",
  image: null,
  rating: 3,
  description: "",

  attributes: []
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});
function EditCollection({ handleOpen, reloadIt, open, handleClose }) {
  const [formData, setFormData] = useState(initialState);
  const [showLoader, setshowLoader] = useState(false);
  const [allCategory, setallCategory] = useState([]);
  const [togglePAge, settogglePAge] = useState(false)
  const [subCategoriesData, setsubCategoriesData] = useState([]);
  const [manageToggleSubCategory, setmanageToggleSubCategory] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate()
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    (async () => {
      try {
        const getNFTDATa = await API_CALL.collection.getEdit({ _id: id });
        if (getNFTDATa.data.success) {
          getCategoryData(getNFTDATa.data.data[0]);
        }
      } catch (error) {
        console.log(error)
      }
    })();


  }, [togglePAge]);
  const checkIsCategoryExist = (temFormData, cat) => {
    let matchedCategory = temFormData.attributes.filter(att => att.category == cat)
    if (!matchedCategory.length) return []
    else return matchedCategory
  }

  const isSubCatAlreadySelected = (cat, sub) => {
    let matchedCategory = checkIsCategoryExist(formData, cat)
    if (!matchedCategory.length) return false
    console.log(matchedCategory, "<<<<matchedSubCateg")

    let attMatchWithCat = matchedCategory?.filter(oneAt => oneAt.category == cat)
    let hasSubCategory = attMatchWithCat[0]?.SubCategory.filter(subcat => subcat._id == sub)
    return hasSubCategory.length ? true : false

  }


  const getCategoryData = async (collectionData) => {
    try {

      const { data } = await API_CALL.SubCategory.getGroupedCategory({});
      if (data.success) {
        setallCategory(data.data);
        let manageAtt = []
        // data.data.map((item) => {
        //   let isCatExist = checkIsCategoryExist(formData, item._id[0]._id)
        //   if (!isCatExist.length) return null
        //   else {
        //     manageAtt.push({
        //       category: item._id[0]._id,

        //     })
        //   }
        //   console.log(item, "<<<thisisItem")
        // });

        setFormData({ ...collectionData });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formatAttributes = (attr) => {
    try {
      console.log(attr, "<<<<thisisattributes")
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async () => {
    try {
      // console.log(formData);
      // return null

      // setshowLoader(true);
      // console.log();
      let payload = new FormData();
      payload.append("title", formData?.title);
      payload.append("attributes", JSON.stringify(formData?.attributes));
      payload.append("nilValue", formData?.nilValue);
      payload.append("description", formData?.description);
      payload.append("rating", JSON.parse(formData?.rating));
      if (typeof formData.image == "object")
        payload.append("image", formData?.image, formData?.image.name);

      const { data } = await API_CALL.collection.update(payload, formData._id);
      if (data.success) {
        setshowLoader(false);
        toast.success(data.message);
        // setFormData(initialState);
        settogglePAge(!togglePAge)
        navigate("/collection-management")
        // reloadIt();
      } else {
        setshowLoader(false);
        toast.error(data.message);
      }
      console.log(data, "<<thisis data");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setshowLoader(false);
    }
  };

  const selectCategory2 = (category, subcategory) => {
    try {
      let attributes = [...formData.attributes]

      let temp = attributes.map((item) => {

        let tempSub = [...item.SubCategory]

        if (item.category == category) {
          let hasSubCategory = item.SubCategory.filter(item => item._id == subcategory)
          return ({
            ...item,
            category: item.category,
            SubCategory: hasSubCategory.length ? tempSub.filter(sub => sub._id != subcategory) : [...item.SubCategory, {}]
          });
        } else {

          return item
        }
      });
      setFormData({ ...formData, attributes: temp })
    } catch (error) {
      console.log(error)
    }

  };

  const selectCategory = (category, subcategory) => {
    try {
      // console.warn(category, subcategory, "<<<thisiscategory,subcategory")
      let isCategoryExist = checkIsCategoryExist(formData, category)
      console.log(isCategoryExist, "<<<thisisisCatExist")
      // return null
      if (!isCategoryExist.length) {

        let tempAtt = [...formData.attributes]
        tempAtt.push({
          category: category,
          SubCategory: [subcategory]
        })
        setFormData({ ...formData, attributes: tempAtt })
      } else {
        let tempAtt = []
        formData.attributes.map(item => {

          if (item.category != category) {

            tempAtt.push(item)
          }
          else {

            let hasSubCategory = item.SubCategory.filter(subItem => subItem._id == subcategory._id)

            if (hasSubCategory.length && item.SubCategory.length == 1) return null
            else {
              tempAtt.push({
                category: category,
                SubCategory: hasSubCategory.length ? item.SubCategory.filter(sub => sub._id != subcategory._id) : [...item.SubCategory, subcategory]
              })
            }
          }
        })
        setFormData({ ...formData, attributes: tempAtt })


      }


    } catch (error) {
      console.log(error)
    }

  };


  return (
    <PageContainer title="Sky Dog Gallery Management" description="">
      <DashboardCard title="Edit NFT">
        <Grid
          container
          rowSpacing={2}
          justifyContent="center"
          columnSpacing={3}
        >
          <Grid item xs={12} md={3} textAlign="center">
            {formData?.image == null ? (
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (ImnageValidation(toast, e.target.files[0]))
                      setFormData({ ...formData, image: e.target.files[0] })
                  }
                  }
                />
              </Button>
            ) : (
              <div>
                <img
                  src={
                    typeof formData?.image == "string"
                      ? formData?.image
                      : URL.createObjectURL(formData?.image)
                  }
                  style={{ width: "100%", objectFit: "contain" }}
                />
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Change Image
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (ImnageValidation(toast, e.target.files[0]))
                        setFormData({ ...formData, image: e.target.files[0] })
                    }
                    }
                  />
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container justifyContent="center" rowGap={2}>
              <Grid item xs={12}>
                <Typography>Enter Nft Name</Typography>
                <TextField
                  fullWidth
                  name="title"
                  onChange={handleChange}
                  value={formData?.title}
                  type="string"
                  placeholder="Enter hash Value"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Enter Hash Value </Typography>
                <TextField
                  fullWidth
                  name="nilValue"
                  onChange={handleChange}
                  value={formData?.nilValue}
                  type="string"
                  placeholder="Enter #"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Enter Rating </Typography>
                <TextField
                  fullWidth
                  name="rating"
                  onChange={handleChange}
                  value={formData?.rating}
                  type="number"
                  placeholder="Enter Rating"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Description </Typography>
                <TextareaAutosize

                  value={formData?.description}
                  onChange={e => {
                    if (formData?.description?.length < 300)
                      setFormData({ ...formData, description: e.target.value })
                  }}
                  style={{ outline: "none", minWidth: "100%", maxWidth: "100%", maxHeight: "6rem", minHeight: "6rem", borderRadius: "10px" }} />
                <div style={{ width: "100%", textAlign: "right" }}>{formData?.description?.length}/300</div>
              </Grid>

              {allCategory?.map((item) => {
                if (item?.data[0]?.parentCategoryData?.length) {
                  return (
                    <Grid container key={item._id[0]._id}>
                      <Typography
                        width="100%"
                        color="white"
                        borderRadius={5}
                        padding={2}
                        bgcolor="#111111"
                      >
                        {item?.data[0]?.parentCategoryData[0]?.name}
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        mt={1}
                        display="flex"
                        flexWrap="wrap"
                        columnGap={1}
                      >
                        {item.data.map(sub => {
                          return <Chip
                            key={sub._id}
                            onClick={() =>
                              selectCategory(
                                item._id[0]._id,
                                sub,
                                // alreadySelected
                              )
                            }
                            label={sub.name}
                            style={{
                              cursor: "pointer",
                              // background: false
                              background: isSubCatAlreadySelected(item._id[0]._id, sub._id)
                                ? MyTheme.bgColor1
                                : null
                            }}
                          />
                        }
                        )}

                      </Grid>
                    </Grid>
                  )
                }
              })}

              <Grid item xs={8} mt={3} md={3} textAlign="center">
                {showLoader ? (
                  <CustomLoader />
                ) : (
                  <Button
                    variant="contain"
                    onClick={handleSubmit}
                    className="cus-btn"
                    fullWidth
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
}

const ShowSubCatComp = ({ item, matchWithCurrentValue, selectCategory, formData }) => {
  const [newData, setnewData] = useState([])
  useEffect(() => {
    setnewData(item.data)
  }, [formData])

  return newData?.map((sub) => {

    const alreadySelected = matchWithCurrentValue(
      item?.data[0]?.parentCategoryData[0]?._id,
      sub._id
    );
    return (
      <Chip
        key={sub._id}
        onClick={() =>
          selectCategory(
            item._id[0]._id,
            sub._id,
            alreadySelected
          )
        }
        label={sub.name}
        style={{
          cursor: "pointer",
          background: alreadySelected
            ? MyTheme.bgColor1
            : null
        }}
      />
    );
  })
}

const ShowStar = () => <span style={{ color: "red" }}>*</span>;

const ShowChip = ({ onClick, data }) => {
  console.log(data.name, "-----------matchshowfiled", data.selected);
  const [value, setvalue] = useState(data.selected);
  useEffect(() => {
    console.log(value, data.name, "<<<<value2");
    setvalue(data.selected);
  }, [data]);

  return (
    <Chip
      key={data._id}
      onClick={onClick}
      label={data.name}
      style={{ cursor: "pointer", background: value && MyTheme.bgColor1 }}
    />
  );
};
export default EditCollection;
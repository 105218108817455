import React, { useEffect, useState } from "react";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "src/components/shared/DashboardCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { API_CALL } from "src/services/APICalls";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";

import {
  convertStringToFormat,
  showDate
} from "src/components/commonfunctions";
import { Button, Grid, Typography } from "@mui/material";
import SeoLib from "../../components/CommonComponents/Helmet";
import AddCategory from "./addGrill";
import { toast } from "react-toastify";
import DeleteCategory from "./DeleteConfirmation";
import EditCategory from "./editGrill";
import { MyTheme } from "src/layouts/customTheme";
import NftCard, {
  CommunityCard,
  PartnerCard,
  RoadmapCard
} from "src/components/CommonComponents/NftCard";
import EditRoadmap from "./editGrill";
import { MyIcons } from "src/components/MyIcons";
import { keyBy } from "lodash";
import EditGrill from "./editGrill";
import BackDropLoader from "src/components/CommonComponents/BackdropLoader";
import DeleteConfirmation from "src/components/CommonComponents/CommonDeletePopup";
function GrillManagement() {
  // modal actions to add users
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null
  });
  const [open, setOpen] = React.useState(false);
  const [editModal, setEditModal] = useState({ open: false, data: null });
  const [reloadPage, setReloadPage] = useState(false);
  const [showBackDrop, setshowBackDrop] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const reloadIt = () => setReloadPage(!reloadPage);
  const onclickEdit = (data) => setEditModal({ show: true, data });


  const [deleteModalData, setdeleteModalData] = useState({
    show: false,
    data: null,

  })
  const [showDeleteLoader, setshowDeleteLoader] = useState(false)
  const closeDeleteModal = () => setdeleteModalData({ show: false, data: null })

  const deleteIt = async () => {
    try {
      setshowDeleteLoader(true)
      const { data } = await API_CALL.grill.delete(deleteModalData.data)
      if (data.success) {
        reloadIt();
        setshowDeleteLoader(false)
        toast.success("Successfully deleted");
        setdeleteModalData({ show: false, data: null })
      } else {
        setshowDeleteLoader(false)
      }
    } catch (error) {
      setshowDeleteLoader(false)
      toast.error(error.message);
      setdeleteModalData({ show: false, data: null })
    }
  };
  // ff
  const openDeleteModal = (id) => {
    setdeleteModalData({ show: true, data: id })
  }

  let ActionButton = (
    <Button variant="contained" onClick={handleOpen} className="cus-btn">
      Add
    </Button>
  );
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setOpen(false);
        setshowBackDrop(true)
        let { data } = await API_CALL.grill.get({});
        console.log(data);
        setTableData(data.data);
        if (data.success) {
          setshowBackDrop(false)

        } else {
          setshowBackDrop(false)

        }
      } catch (error) {
        toast.error("No Data found");
        setshowBackDrop(false)
        setTableData([])
        console.log(error);
      }
    })();
  }, [reloadPage]);
  // const DeleteGrill = async (id) => {
  //   try {
  //     const { data } = await API_CALL.grill.delete(id)
  //     if (data.success) {
  //       toast.success(data.message)
  //       reloadIt()

  //     } else {
  //       toast.error(data.message)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  return (
    <PageContainer title="Sky Dog Grill Management" description="">
      <DashboardCard title="Grill" action={ActionButton}>
        {/* <SeoLib title="User Management" /> */}
        <AddCategory
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
          reloadIt={reloadIt}
        />
        {deleteModalData.show && <DeleteConfirmation showDeleteLoader={showDeleteLoader} open={deleteModalData.show} handleSubmit={deleteIt} closeDeleteModal={closeDeleteModal} />}

        {/* <DeleteCategory
          reloadIt={reloadIt}
          data={deleteModal}
          handleClose={() => setDeleteModal({ open: false })}
        /> */}
        {showBackDrop && <BackDropLoader />}

        {editModal.open && (
          <EditRoadmap
            handleClose={() => setEditModal({ open: false, data: {} })}
            reloadIt={reloadIt}
            open={editModal.open}
            data={editModal.data}
          />
        )}

        <Grid container rowGap={2} rowSpacing={2}>
          <Grid item xs={12} mt={2}>
            {tableData?.map((item, key) => {
              return (
                <div style={{ marginTop: "20px" }}>
                  <Grid container rowGap={1}>
                    {" "}
                    <Grid item xs={8} md={8}>
                      <Typography fontSize={18}>Grid {key + 1} </Typography>
                    </Grid>
                    <Grid item xs={4} md={4} textAlign="right">
                      <Button onClick={() => {
                        setEditModal({ open: true, data: item })
                      }}>Edit</Button>
                      <Button color="error" onClick={() => {

                        setdeleteModalData({ show: true, data: item._id })
                      }}>Delete</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <GrillCard
                        content={item.content}
                        key={item._id}
                        index={key}
                      />
                    </Grid>
                  </Grid>

                </div>
              );
            })}
          </Grid>
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
}

const GrillCard = ({ content, key }) => {
  return (
    <div className="grill-cover">
      {content?.map((item) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "20px",
              width: "100%",

            }}
          >

            <MyIcons.StarIcon /> <span style={{ width: "100%", textWrap: "nowrap" }}>{item}</span>{" "}
          </div>
        );
      })}
    </div>
  );
};


export default GrillManagement;

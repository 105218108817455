// export const dis

import { REDUX_VAR } from "./ReduxVar";

const toggleTheme = (dispatch, value) =>
  dispatch({ type: REDUX_VAR.SHOW_THEME });
const saveMetamaskDetails = (dispatch, payload) =>
  dispatch({ type: REDUX_VAR.METAMASK, payload });

const saveSession = async () => {
  await sessionStorage.setItem("skydoguser", true)
}
const getSession = async () => {
  return await sessionStorage.getItem("skydoguser")
}
const removeSession = async () => {
  return await sessionStorage.removeItem("skydoguser")
}
export const MANAGELOCALSTORAGE = {
  saveToken: (token) => { localStorage.setItem("skydog_token", token) },
  getToken: () => localStorage.getItem("skydog_token"),
}
export const ACTIONS = {
  toggleTheme,
  saveMetamaskDetails,
  saveSession, getSession, removeSession
};

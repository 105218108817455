import EditIcon from '@mui/icons-material/Edit';
// import TypeIcon from "../assets/images/logos/typeicon.webp"
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';




export const MyIcons={
    EditIcon
    ,DeleteIcon,
    StarIcon,CloudUploadIcon
}
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useLocation, useNavigate, useNavigation, useRoutes } from "react-router-dom";
import Router from "./routes/Router";
import "./styles/global.css";
import "./styles/category.css";
import "react-toastify/dist/ReactToastify.css";

import { baselightTheme } from "./theme/DefaultColors";
import { Provider } from "react-redux";
import { store } from "../src/Redux/Store";
import MetamaskLogin from "./components/metamask/metamasklogin";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { ACTIONS, MANAGELOCALSTORAGE } from "./Redux/Actions";
import { API_CALL } from "./services/APICalls";
// dfd
function App() {
  const routing = useRoutes(Router);
  const { pathname } = useLocation()
  const theme = baselightTheme;
  const navigate = useNavigate()


  useEffect(() => {

    (async () => {
      try {
        let token = MANAGELOCALSTORAGE.getToken()
        if (!token) navigate("/auth/login")
        else {
          let { data } = await API_CALL.users.validateSession({})
          console.log(data, "<<<<thisisData")
          if (data.success) {
            // navigate("/")
          }
        }
      } catch (error) {
        console.log(error)
        navigate("/auth/login")
      }

    })()

  }, [pathname])

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* <MetamaskLogin /> */}
        <CssBaseline />
        {routing}
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize
} from "@mui/material";
import { API_CALL } from "src/services/APICalls";
import CustomLoader from "src/components/custom-scroll/CustomLoader";
import { toast } from "react-toastify";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "src/components/shared/DashboardCard";
import { MyTheme } from "src/layouts/customTheme";
import { useNavigate } from "react-router";
import { ImnageValidation } from "src/components/commonfunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4
};

let initialState = {
  title: "",
  image: null,
  rating: 3,
  description: "",
  attributes: []
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});
function AddGallery({ handleOpen, reloadIt, open, handleClose }) {
  const [formData, setFormData] = useState(initialState);
  const [showLoader, setshowLoader] = useState(false);
  const [attributesArray, setattributesArray] = useState([]);
  const [allCategory, setallCategory] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {

    getCategoryData();

    // console.log("called")
  }, []);

  const getCategoryData = async () => {
    try {
      const { data } = await API_CALL.SubCategory.getGroupedCategory({});
      if (data.success) {
        setallCategory(data.data);
        const manageAtt = data.data.map((item) => {
          return {
            category: item._id[0],
            SubCategory: item.data.map((sub) => ({ ...sub, selected: false }))
          };
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(formData, "<<<this is formdat");

  const handleSubmit = async () => {
    try {
      console.log(attributesArray);
      setshowLoader(true);
      console.log();
      let payload = new FormData();
      payload.append("title", formData?.title);
      payload.append("nilValue", formData?.nilValue);
      payload.append("attributes", JSON.stringify(formData.attributes));
      payload.append("description", formData.description);
      payload.append("rating", formData.rating);
      payload.append("image", formData?.image, formData?.image.name);

      const { data } = await API_CALL.collection.create(payload);
      if (data.success) {
        setshowLoader(false);
        toast.success(data.message);
        setFormData(initialState);
        navigate("/collection-management");
        // reloadIt();
      } else {
        setshowLoader(false);
        toast.error(data.message);
      }
      console.log(data, "<<thisis data");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setshowLoader(false);
    }
  };

  const selectCategory = (category, subcategory) => {

    // return null

    const checkCat = formData.attributes.filter(
      (item) => item.category == category
    );

    if (checkCat.length) {

      let tempAtt = []
      formData.attributes.map(item => {

        if (item.category != category) {

          tempAtt.push(item)
        }
        else {
          let hasSubCategory = item.SubCategory.filter(subItem => subItem._id == subcategory._id)

          if (hasSubCategory.length && item.SubCategory.length == 1) return null
          else {
            tempAtt.push({
              category: category,
              SubCategory: hasSubCategory.length ? item.SubCategory.filter(sub => sub._id != subcategory._id) : [...item.SubCategory, subcategory]
            })
          }
        }
      })
      setFormData({ ...formData, attributes: tempAtt })
    } else {
      let tempAtt = [...formData.attributes]
      setFormData({ ...formData, attributes: [...tempAtt, { category, SubCategory: [subcategory] }] })

    }
  };
  const selectCategory2 = (category, subcategory) => {

    // return null

    const checkCat = attributesArray.filter(
      (item) => item.category == category
    );

    if (checkCat.length) {
      setattributesArray(attributesArray.map(it => {
        if (it.category != category) return it
        else {
          if (it.SubCategory.includes(subcategory)) {
            return ({
              category, SubCategory: it.SubCategory.filter(sub => sub != subcategory)
            })
          } else {

            return ({
              category, SubCategory: [...it.SubCategory, subcategory]
            })
          }

        }
      }))
    } else {
      setattributesArray([
        ...attributesArray,
        { category, SubCategory: [subcategory] }
      ]);
    }
  };

  const isSubCatAlreadySelected = (category, subcategory) => {
    let checkCat = formData.attributes.filter(item => item.category == category)
    if (!checkCat.length) return false
    else {
      let selectedCat = (formData.attributes.filter(item => item.category == category))[0]
      let hasSubCat = selectedCat.SubCategory.filter(it => it._id == subcategory)
      return hasSubCat.length ? true : false
    }
  }

  return (
    <PageContainer title="Sky Dog Gallery Management" description="">
      <DashboardCard title="Add Item">
        <Grid
          container
          rowSpacing={2}
          justifyContent="center"
          columnSpacing={3}
        >
          <Grid item xs={12} md={3} textAlign="center">
            {formData?.image == null ? (
              <div
                style={{
                  border: "1px solid gray",
                  height: "15rem",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (ImnageValidation(toast, e.target.files[0]))
                        setFormData({ ...formData, image: e.target.files[0] })
                    }
                    }
                  />
                </Button>
              </div>
            ) : (
              <div>
                <img
                  src={URL.createObjectURL(formData?.image)}
                  style={{ width: "100%", objectFit: "contain" }}
                />
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Change Image
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (ImnageValidation(toast, e.target.files[0]))
                        setFormData({ ...formData, image: e.target.files[0] })
                    }
                    }
                  />
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container justifyContent="center" rowGap={2}>
              <Grid item xs={12}>
                <Typography>Enter NFt Name</Typography>
                <TextField
                  fullWidth
                  name="title"
                  onChange={handleChange}
                  value={formData?.title}
                  type="string"
                  placeholder="Enter Full Name"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Enter Hash Value </Typography>
                <TextField
                  fullWidth
                  name="nilValue"
                  onChange={handleChange}
                  value={formData?.nilValue}
                  type="string"
                  placeholder="Enter #"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Enter Rating </Typography>
                <TextField
                  fullWidth
                  name="rating"
                  onChange={handleChange}
                  value={formData?.rating}
                  type="number"
                  placeholder="Enter Rating"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Description </Typography>
                <TextareaAutosize

                  value={formData?.description}
                  onChange={e => {
                    if (formData?.description?.length < 300)
                      setFormData({ ...formData, description: e.target.value })
                  }}
                  style={{ outline: "none", minWidth: "100%", maxWidth: "100%", maxHeight: "6rem", minHeight: "6rem", borderRadius: "10px" }} />
                <div style={{ width: "100%", textAlign: "right" }}>{formData?.description?.length}/300</div>
              </Grid>

              {allCategory?.map((item) => {
                if (item?.data[0]?.parentCategoryData?.length) {
                  return (
                    <Grid container key={item._id[0]._id}>
                      <Typography
                        width="100%"
                        color="white"
                        borderRadius={5}
                        padding={2}
                        bgcolor="#111111"
                      >
                        {item?.data[0]?.parentCategoryData[0]?.name}
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        mt={1}
                        display="flex"
                        flexWrap="wrap"
                        columnGap={1}
                      >
                        {item.data.map(sub => {
                          return <Chip
                            key={sub._id}
                            onClick={() =>
                              selectCategory(
                                item._id[0]._id,
                                sub,
                                // alreadySelected
                              )
                            }
                            label={sub.name}
                            style={{
                              cursor: "pointer",
                              background: isSubCatAlreadySelected(item._id[0]._id, sub._id)
                                ? MyTheme.bgColor1
                                : null
                            }}
                          />
                        }
                        )}

                      </Grid>
                    </Grid>
                  )
                }
              })}

              <Grid item xs={8} mt={3} md={3} textAlign="center">
                {showLoader ? (
                  <CustomLoader />
                ) : (
                  <Button
                    variant="contain"
                    onClick={handleSubmit}
                    className="cus-btn"
                    fullWidth
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
}

const ShowStar = () => <span style={{ color: "red" }}>*</span>;

const ShowChip = ({ onClick, data }) => {
  console.log(data.name, "-----------matchshowfiled", data.selected);
  const [value, setvalue] = useState(data.selected);
  useEffect(() => {
    console.log(value, data.name, "<<<<value2");
    setvalue(data.selected);
  }, [data]);

  return (
    <Chip
      key={data._id}
      onClick={onClick}
      label={data.name}
      style={{ cursor: "pointer", background: value && MyTheme.bgColor1 }}
    />
  );
};
export default AddGallery;

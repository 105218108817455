import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { API_CALL } from 'src/services/APICalls';
import { toast } from 'react-toastify';
import { Box, Grid, Switch } from '@mui/material';
import { showDate } from '../commonfunctions';
import EditRoadmap from 'src/views/Roadmap/EditRoadmap';
import { useNavigate } from 'react-router';
const commonImageStyle = {
  width: "100%", height: "100%", objectFit: "cover", aspectRatio: "1/1"
}
const cardsCommonStyle = {
  width: "350px"
}
export default function NftCard({ item, reloadIt, setEditModal, openDeleteModal }) {
  const navigate = useNavigate()

  const deleteCard = async () => {
    openDeleteModal(item._id)
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px", }} style={{ border: "1px solid #ececec" }}>
      <Box >
        <img
          src={item.image}
          alt={item.title}

          style={
            commonImageStyle

          }
        />
      </Box>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray">
            {item?.category?.name}
          </Typography>
        </div>
        <Box>
          <Grid container>
            {/* <Grid item xs={5} textAlign="left">
Slider
      <Switch defaultChecked={item?.showInHomeSlider} onClick={()=>{
        manageBanner()
      }}/>
          </Grid> */}
            {/* <Grid item xs={5} textAlign="left">
Ecosystem
      <Switch defaultChecked={item?.showInEcosystem} onClick={e=>{
        console.log(e.target.value)
        
        showToggle()
      }}/>
          </Grid> */}
            <Grid item xs={9} textAlign="right">
              <Button size="small" color="primary" onClick={() => {
                navigate("/edit-gallery/" + item._id)
              }} > Edit
              </Button>
            </Grid>
            <Grid item xs={3} textAlign="left" >
              <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
      {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}

      <div>

      </div>


    </Card>
  );
}
export const CommunityCard = ({ item, openDeleteModal, reloadIt, setEditModal }) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update({ showInHomeSlider: !item.showInHomeSlider }, item._id)
      if (data.success) {
        item.showInHomeSlider ?
          toast.success("NFT has been removed from Slider") : toast.success("Nft has been added to Slider")
        reloadIt()
      }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id)
      // const { data } = await API_CALL.Community.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px" }} style={{ border: "1px solid #ececec" }}>
      <Box >
        <img
          src={item.image}
          alt={item.title}

          style={
            commonImageStyle

          }
        />
      </Box>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray">
            {item?.category?.name}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
        <Button size="small" color="primary" onClick={() => setEditModal({ open: true, data: item })}> Edit</Button>
      </CardActions>
    </Card>
  );
}
export const PartnerCard = ({ item, openDeleteModal, reloadIt, setEditModal }) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update({ showInHomeSlider: !item.showInHomeSlider }, item._id)
      if (data.success) {
        item.showInHomeSlider ?
          toast.success("NFT has been removed from Slider") : toast.success("Nft has been added to Slider")
        reloadIt()
      }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id)
      // const { data } = await API_CALL.Partner.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px" }} style={{ border: "1px solid #ececec" }}>
      <Box >
        <img
          src={item.image}
          alt={item.title}

          style={
            commonImageStyle
          }
        />
      </Box>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray">
            {item?.category && item?.category}
          </Typography>
        </div>

      </CardContent>
      <CardActions style={{ padding: "0 10px", textAlign: "right" }}>
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
        <Button size="small" color="primary" onClick={() => setEditModal({ open: true, data: item })}> Edit</Button>
      </CardActions>
    </Card>
  );
}
export const SliderCard = ({ item, reloadIt, clickEdit, openDeleteModal = () => { } }) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update({ showInHomeSlider: !item.showInHomeSlider }, item._id)
      if (data.success) {
        item.showInHomeSlider ?
          toast.success("NFT has been removed from Slider") : toast.success("Nft has been added to Slider")
        reloadIt()
      }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id)
      // const {data} =await API_CALL.Slider.delete(item._id)
      // if(data.success) {

      //     toast.success("NFT successfully Deleted")
      //     reloadIt()
      // }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px" }} style={{ border: "1px solid #ececec" }}>
      <Box >
        <img
          src={item.image}
          alt={item.title}

          style={
            commonImageStyle

          }
        />
      </Box>

      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray">
            {item?.category}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>


          {/* <Typography gutterBottom variant="h5" component="div" color="gray">
          #{item?.nilValue} 
        </Typography> */}
        </div>
      </CardContent>
      <CardActions style={{ padding: "0 10px", textAlign: "right" }}>
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
        <Button size="small" color="primary" onClick={clickEdit}> Edit</Button>
      </CardActions>

    </Card>
  );
}
export const RoadmapCard = ({ item, onclickEdit, openDeleteModal, reloadIt }) => {

  const deleteCard = async () => {
    openDeleteModal(item._id)
    // try {
    //   const { data } = await API_CALL.Roadmap.delete(item._id)
    //   if (data.success) {

    //     toast.success("NFT successfully Deleted")
    //     reloadIt()
    //   }
    // } catch (error) {
    //   console.log(error)
    //   toast.error("Error while updating NFT")
    // }
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px" }} style={{ border: "1px solid #ececec" }}>
      <div style={{
        aspectRatio: "1/1",
        width: "100%"
      }}>

        <Box >
          <img
            src={item.image}
            alt={item.title}

            style={
              commonImageStyle
            }
          />
        </Box>
      </div>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray" fontSize={13}>
            {showDate(item?.date)}
          </Typography>
        </div>

      </CardContent>
      <CardActions style={{ padding: "0 10px", textAlign: "right" }}>
        {/* <EditRoadmap open={openED} /> */}
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
        <Button size="small" color="primary" onClick={() => onclickEdit(item)}> Edit</Button>
      </CardActions>
    </Card>
  );
}
export const ArtistCard = ({ openViewModal, openDeleteModal, item, onclickEdit, reloadIt }) => {
  const [openEditModal, setopenEditModal] = React.useState({ show: false, data: {} })
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update({ showInHomeSlider: !item.showInHomeSlider }, item._id)
      if (data.success) {
        item.showInHomeSlider ?
          toast.success("NFT has been removed from Slider") : toast.success("Nft has been added to Slider")
        reloadIt()
      }
    } catch (error) {
      console.log(error)
      toast.error("Error while updating NFT")
    }
  }
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id)
      // const { data } = await API_CALL.Artist.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error)
      toast.error("Error while Deleting NFT")
    }
  }
  return (
    <Card sx={{ ...cardsCommonStyle, borderRadius: "10px" }} style={{ border: "1px solid #ececec" }}>
      <Box >
        <img
          src={item.image}
          alt={item.title}

          style={
            commonImageStyle

          }
        />
      </Box>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray" fontSize={13}>
            {showDate(item?.date)}
          </Typography>
        </div>

      </CardContent>
      <CardActions style={{ padding: "0px 10px", textAlign: "right" }}>
        {/* <EditRoadmap open={openED} /> */}
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}> Delete</Button>
        <Button size="small" color="primary" onClick={() => onclickEdit(item)}> Edit</Button>
      </CardActions>
    </Card>
  );
}
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import BackDropLoader from 'src/components/CommonComponents/BackdropLoader'
import DeleteConfirmation from 'src/components/CommonComponents/CommonDeletePopup'
import ContactTable from 'src/components/CommonComponents/ContactTable'
import NoDataFound from 'src/components/CommonComponents/NoDataFound'
import PageContainer from 'src/components/container/PageContainer'
import DashboardCard from 'src/components/shared/DashboardCard'
import { API_CALL } from 'src/services/APICalls'

function ViewData() {
  const [Messages, setMessages] = useState([])
  const [toggle, setToggle] = useState(false)
  const [showBackDrop, setshowBackDrop] = useState(false)
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null
  });
  const reloadPage = () => setToggle(!toggle)
  useEffect(() => {
    (async () => {
      try {
        setshowBackDrop(true)

        const { data } = await API_CALL.Contact.get({})
        if (data.success) {
          setshowBackDrop(false)
          setMessages(data.data)
        } else {
          setshowBackDrop(false)
        }
      } catch (error) {
        console.log(error)
        setshowBackDrop(false)

        toast.error(error?.response?.data?.message)
      }
    })()
  }, [toggle])
  const [deleteModalData, setdeleteModalData] = useState({
    show: false,
    data: null,

  })
  const [showDeleteLoader, setshowDeleteLoader] = useState(false)
  const closeDeleteModal = () => setdeleteModalData({ show: false, data: null })

  const deleteIt = async () => {
    try {
      setshowDeleteLoader(true)

      const { data } = await API_CALL.Contact.delete(deleteModalData.data);
      if (data.success) {
        reloadPage();
        setshowDeleteLoader(false)
        toast.success("Successfully deleted");
        setdeleteModalData({ show: false, data: null })
      } else {
        setshowDeleteLoader(false)
      }
    } catch (error) {
      setshowDeleteLoader(false)
      toast.error(error.message);
      setdeleteModalData({ show: false, data: null })
    }
  };
  const openDeleteModal = (id) => {
    setdeleteModalData({ show: true, data: id })
  }



  return (
    <PageContainer title="Sky Dog Contact Management" description="">
      {deleteModalData.show && <DeleteConfirmation showDeleteLoader={showDeleteLoader} open={deleteModalData.show} handleSubmit={deleteIt} closeDeleteModal={closeDeleteModal} data={deleteModal.data} />}

      <DashboardCard title="Messages" >
        {showBackDrop && <BackDropLoader />}

        {Messages.length ? <ContactTable tableData={Messages} openDeleteModal={openDeleteModal} reloadPage={reloadPage} /> : <NoDataFound />}
      </DashboardCard>
    </PageContainer>
  )
}

export default ViewData